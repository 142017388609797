import React, { type MouseEvent } from 'react';
import { type BarDatum, type BarCustomLayerProps } from '@nivo/bar/dist/types/types';
import { useTooltip } from '@nivo/tooltip';

export const TransparentBars = <T extends BarDatum>(props: BarCustomLayerProps<T>) => {
  const { bars, innerHeight } = props;
  const tip = useTooltip();

  const singleSidePadding = bars.length > 0 ? (bars[1].x - bars[0].x - bars[0].width) / 2 : 0;

  const renderTip = (e: MouseEvent, i: number) => {
    tip.showTooltipFromEvent(
      React.createElement(props.tooltip, {
        ...bars[i],
        ...bars[i].data,
        value: bars[i].data.value || 0,
      }),
      e,
    );
  };

  return (
    <g>
      {bars.map((bar, i) => (
        <rect
          key={bar.key}
          x={bar.x - singleSidePadding}
          y={0}
          height={innerHeight}
          width={bar.width + singleSidePadding * 2}
          fill="transparent"
          onMouseEnter={(e) => renderTip(e, i)}
          onMouseMove={(e) => renderTip(e, i)}
          onMouseLeave={() => tip.hideTooltip()}
        />
      ))}
    </g>
  );
};
