import { type BaseChainflipAsset, baseChainflipAssets } from '@chainflip/utils/chainflip';
import { addDays, addHours, differenceInDays, startOfHour } from 'date-fns';

export const getDateAxisTicks = <T>(dates: T[], numberOfTicks = 15) => {
  const datesPerTick = Math.ceil(dates.length / numberOfTicks);
  const datesOutsideOfTicks = (dates.length - 1) % datesPerTick;

  const selectedIndices = [...Array(numberOfTicks).keys()] // [0,1,2,...,10]
    .map((i) => i * datesPerTick) // [0,2,4,...,20]
    .map((i) => i + Math.ceil(datesOutsideOfTicks / 2)) // [1,3,5,...,21]
    .filter((i) => i < dates.length);

  return selectedIndices.map((tickIndex) => dates[tickIndex]);
};

export const getEmptyTickMap = <T>(
  chartStartDate: Date,
  chartEndDate: Date,
  setEmptyTick: (date: Date) => T,
) => {
  const map = new Map<number, T>();
  const daysBack = differenceInDays(chartEndDate, chartStartDate);

  const startTimestamp =
    daysBack === 1
      ? // for 24 hours, we truncate timestamps to the hour
        startOfHour(chartEndDate).valueOf()
      : // otherwise the server truncates to the UTC day
        Date.UTC(
          chartEndDate.getFullYear(),
          chartEndDate.getMonth(),
          chartEndDate.getDate(),
        ).valueOf();
  const start = startTimestamp - daysBack * 3600 * 24 * 1000;

  const stepper = daysBack === 1 ? addHours : addDays;

  const stop = chartEndDate.getTime();

  // we rely on the order of the map to ensure the data is sorted correctly
  for (let current = start; current <= stop; current = stepper(current, 1).valueOf()) {
    const currentDate = new Date(current);

    map.set(
      daysBack === 1
        ? current.valueOf()
        : Date.UTC(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate(),
          ).valueOf(),
      setEmptyTick(new Date(current)),
    );
  }

  return map;
};

export const colors = ['#43E1E1', '#FFD2A8', '#57FFAE', '#634DBA', '#FF6157', '#595959'];

export const emptyBaseAssetsRecord = baseChainflipAssets.reduce(
  (map, asset) => ({ ...map, [asset]: 0 }),
  {} as Record<BaseChainflipAsset, number>,
);
