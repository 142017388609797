import { useMemo } from 'react';
import { differenceInUtcDays } from '@chainflip/utils/date';
import { type UTCDate } from '@date-fns/utc';
import { ChartLegend, SkeletonLine } from '@/shared/components';
import GraphLoader from '@/shared/components/GraphLoader';
import { getTimeframeOrFormattedDateLabels } from '@/shared/utils';
import { colors } from '@/shared/utils/charts';

export function AccountStatsWithChart<
  RawData,
  ChartData extends { chartDates: UTCDate[]; total: string },
  Stat extends string,
  StatInfo extends Record<Stat, { title: string; subtitle: string; tableColumnLabel: string }>,
  Account extends { key: string | number },
  OverviewStats extends Record<Stat, { formattedTotalValue: string; topAccounts: Account[] }>,
>({
  stat,
  loading,
  startDate,
  endDate,
  rawData,
  aggregateData,
  getLegendLabels,
  Chart,
  overviewStats,
  statInfo,
  Badge,
  AccountLegendLine,
}: {
  stat: Stat;
  loading: boolean;
  startDate: UTCDate;
  endDate: UTCDate;
  rawData: RawData;
  aggregateData: (startDate: UTCDate, endDate: UTCDate, data: RawData) => ChartData;
  getLegendLabels: (data: ChartData) => string[];
  Chart: React.FC<{ data: ChartData; loading: boolean }>;
  overviewStats: OverviewStats;
  statInfo: StatInfo;
  Badge: React.FC;
  AccountLegendLine: React.FC<{ account: Account; stat: Stat }>;
}) {
  const chartData = useMemo(
    () => aggregateData(startDate, endDate, rawData),
    [startDate, endDate, rawData, aggregateData],
  );

  const numberOfDays = differenceInUtcDays({
    // differenceInUtcDays checks for full-day difference
    // adding 1 second to the end date to round it up so we handle the common scenario [startOfDay, endOfDay]
    end: endDate.getTime() + 1000,
    start: startDate,
  });

  const { title, subtitle, tableColumnLabel } = statInfo[stat];

  const { formattedTotalValue, topAccounts } = overviewStats[stat];

  return (
    <div className="flex flex-col md:flex-row">
      <div className="flex min-h-[500px] min-w-[320px] flex-col items-center justify-center gap-y-5 border border-cf-gray-3-5 bg-cf-gray-2 p-5 md:rounded-bl-md">
        <Badge />

        <div className="flex flex-col items-center gap-y-4 text-white">
          <div className="text-[32px] leading-[20px]">
            {!loading ? (
              <span>{formattedTotalValue}</span>
            ) : (
              <SkeletonLine width={150} height={20} />
            )}
          </div>
          <div className="text-16">
            {title} <span className="text-cf-light-2">/ All Time</span>
          </div>
        </div>

        <div className="flex w-full flex-col gap-y-1.5 px-5 text-12">
          <div className="flex flex-row items-center justify-between text-cf-light-2">
            <div>Top 5</div>
            <div>{tableColumnLabel}</div>
          </div>
          {!loading ? (
            topAccounts.map((account) => (
              <AccountLegendLine account={account} stat={stat} key={account.key} />
            ))
          ) : (
            <SkeletonLine count={5} />
          )}
        </div>
      </div>
      <div className="flex-grow space-y-2 rounded-b-md border border-t-0 border-cf-gray-3-5 bg-cf-gray-2 p-5 md:rounded-bl-none md:border-l-0 md:border-t">
        <div className="flex items-start justify-between text-cf-light-2">
          <div>
            <div className="text-16">
              <span className="text-cf-white">{title}</span> / {numberOfDays} days
            </div>
            <div className="text-12">{subtitle}</div>
          </div>
          <div className="flex flex-col items-end text-14">
            <div className="text-cf-white">
              {loading ? <SkeletonLine width={150} height={20} /> : chartData.total}
            </div>
            <span className="text-12" suppressHydrationWarning>
              {getTimeframeOrFormattedDateLabels([startDate, endDate])}
            </span>
          </div>
        </div>
        <ChartLegend
          items={getLegendLabels(chartData).map((label, i) => ({
            label,
            color: colors[i],
          }))}
        />
        <GraphLoader className="-m-4 h-[350px] [&_*]:!font-aeonikRegular" loading={loading}>
          <Chart data={chartData} loading={loading} />
        </GraphLoader>
      </div>
    </div>
  );
}
